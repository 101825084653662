import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Alvearie/alvearie.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Alvearie Components`}</h2>
    <p>{`Alvearie is made up of many individual services and components that can be used together to build a health
data pipeline. Below is a list of those services, which will be growing over time.`}</p>
    <p />
    <Row mdxType="Row">
      <Column colMd={2} colLg={3} noGutterMdLeft mdxType="Column">
        <ResourceCard actionIcon="launch" color="dark" title="IBM FHIR Server" href="https://ibm.github.io/FHIR/" mdxType="ResourceCard">
          <a {...{
            "href": "https://github.com/ibm/fhir"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "21px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACxklEQVQ4y41UzYtSURS/Mo6OJTS7SZjAv6I2QVjjMAu3/QHiIqQoq81ItBJcJO1bFdimhcm0EKSgNklKFooIiiSKH/iFqOHX8z1f51zPfXNnaKgHv3fvufec3/3de+65TNd1JhCLxdje3p4ln8/fA1tfr9cLffOtCcZYoVC4b7VaLdFo1IiHOcZkwmQyySKRiB2cH1DwCrDWTz80V9gpFouBUChkj8fjZzjwZxLsgAMpUAFoRKgi1psPxxSwNXI8wliZUMh1iy2dFcXH9AvGFtR3G4Rg3ITObXJYAqZgp6FNr1arrqqqc7BTCOjPYKwHc9/IB32XtJhbKBSrT6n/VdM0vlq5XD7o9XpPZrMZm8/nDPqPKpXKEc6hD/h+IcFT4rAwkq2Kw4avTvJ30+k0a7fbbLlcXgLYWq0Wy2azfI58fonkYRMOh/eZyCI1mIhneMigYKvRaGwNh0MzgBHMOIZzlMRjact6qVQ6YZRRjdTlaWUbP48LPkqijUh/kCDOIRRqtEqZCM3/IgRsU1ukWBCuKecVGoT81v8nIcVuFFJJiUsqtnzFuFd/367sk5UI54xuv0jMb8BDCtjt9/tmj8djkPl8PlO32zVLWQ4AVucVzim7chU8xblms8n8fj8nc7lcLBgMsnq9Lrb8WLodKuEuk8qqMRgMro7H4xfE+wHsV16vdx8JnU4nCwQCTlD9BuY+UU3rdIdV4uFncovKBtP0PJPJXBZKFUX5SWosIvNwwT+SMvFgqOR+uFgsNoecSCRMECx43gHxDuAa9O2pVIrlcjkTlN/OZDJB/9dSESDZDGOhirahPX1twLguvSLfAV3AS0mhlTL8Vrw04tzhwbhh3AowOEajETpbAHek5LynjCOZlSojKs0fYgycOwrC42D81UBUq1W+gsPh4C1sATP9mVa2CoWYkE6ncyzuKLa1Wo1zoLA/vKDj//bZmWcAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "GitHub",
                "title": "GitHub",
                "src": "/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png",
                "srcSet": ["/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png 21w"],
                "sizes": "(max-width: 21px) 100vw, 21px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></a>
        </ResourceCard>
      </Column>
      <Column colMd={6} colLg={9} noGutterMdLeft mdxType="Column">
IBM's implementation of the HL7 FHIR specification simplifies data management by allowing you to exchange disparate datasets 
between applications that were previously incompatible.  &nbsp;
        <a href="https://www.ibm.com/products/fhir-server" target="_blank">Learn more about the IBM FHIR Server</a>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={2} colLg={3} noGutterMdLeft mdxType="Column">
        <ResourceCard actionIcon="launch" color="dark" title="Health Record Ingestion" href="https://alvearie.github.io/HRI/" mdxType="ResourceCard">
          <a {...{
            "href": "https://github.com/Alvearie/HRI"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "21px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACxklEQVQ4y41UzYtSURS/Mo6OJTS7SZjAv6I2QVjjMAu3/QHiIqQoq81ItBJcJO1bFdimhcm0EKSgNklKFooIiiSKH/iFqOHX8z1f51zPfXNnaKgHv3fvufec3/3de+65TNd1JhCLxdje3p4ln8/fA1tfr9cLffOtCcZYoVC4b7VaLdFo1IiHOcZkwmQyySKRiB2cH1DwCrDWTz80V9gpFouBUChkj8fjZzjwZxLsgAMpUAFoRKgi1psPxxSwNXI8wliZUMh1iy2dFcXH9AvGFtR3G4Rg3ITObXJYAqZgp6FNr1arrqqqc7BTCOjPYKwHc9/IB32XtJhbKBSrT6n/VdM0vlq5XD7o9XpPZrMZm8/nDPqPKpXKEc6hD/h+IcFT4rAwkq2Kw4avTvJ30+k0a7fbbLlcXgLYWq0Wy2azfI58fonkYRMOh/eZyCI1mIhneMigYKvRaGwNh0MzgBHMOIZzlMRjact6qVQ6YZRRjdTlaWUbP48LPkqijUh/kCDOIRRqtEqZCM3/IgRsU1ukWBCuKecVGoT81v8nIcVuFFJJiUsqtnzFuFd/367sk5UI54xuv0jMb8BDCtjt9/tmj8djkPl8PlO32zVLWQ4AVucVzim7chU8xblms8n8fj8nc7lcLBgMsnq9Lrb8WLodKuEuk8qqMRgMro7H4xfE+wHsV16vdx8JnU4nCwQCTlD9BuY+UU3rdIdV4uFncovKBtP0PJPJXBZKFUX5SWosIvNwwT+SMvFgqOR+uFgsNoecSCRMECx43gHxDuAa9O2pVIrlcjkTlN/OZDJB/9dSESDZDGOhirahPX1twLguvSLfAV3AS0mhlTL8Vrw04tzhwbhh3AowOEajETpbAHek5LynjCOZlSojKs0fYgycOwrC42D81UBUq1W+gsPh4C1sATP9mVa2CoWYkE6ncyzuKLa1Wo1zoLA/vKDj//bZmWcAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "GitHub",
                "title": "GitHub",
                "src": "/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png",
                "srcSet": ["/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png 21w"],
                "sizes": "(max-width: 21px) 100vw, 21px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></a>
        </ResourceCard>
      </Column>
      <Column colMd={6} colLg={9} noGutterMdLeft mdxType="Column">
	A "front door" for data integrators to send healthcare data into the Alvearie environment, which supports both batch 
	processing and data streaming workflows.
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={2} colLg={3} noGutterMdLeft mdxType="Column">
        <ResourceCard actionIcon="" color="dark" title="HRI Pipeline Adapters" mdxType="ResourceCard">
          <a {...{
            "href": "https://github.com/Alvearie/hri-pipeline-adapters"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "21px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACxklEQVQ4y41UzYtSURS/Mo6OJTS7SZjAv6I2QVjjMAu3/QHiIqQoq81ItBJcJO1bFdimhcm0EKSgNklKFooIiiSKH/iFqOHX8z1f51zPfXNnaKgHv3fvufec3/3de+65TNd1JhCLxdje3p4ln8/fA1tfr9cLffOtCcZYoVC4b7VaLdFo1IiHOcZkwmQyySKRiB2cH1DwCrDWTz80V9gpFouBUChkj8fjZzjwZxLsgAMpUAFoRKgi1psPxxSwNXI8wliZUMh1iy2dFcXH9AvGFtR3G4Rg3ITObXJYAqZgp6FNr1arrqqqc7BTCOjPYKwHc9/IB32XtJhbKBSrT6n/VdM0vlq5XD7o9XpPZrMZm8/nDPqPKpXKEc6hD/h+IcFT4rAwkq2Kw4avTvJ30+k0a7fbbLlcXgLYWq0Wy2azfI58fonkYRMOh/eZyCI1mIhneMigYKvRaGwNh0MzgBHMOIZzlMRjact6qVQ6YZRRjdTlaWUbP48LPkqijUh/kCDOIRRqtEqZCM3/IgRsU1ukWBCuKecVGoT81v8nIcVuFFJJiUsqtnzFuFd/367sk5UI54xuv0jMb8BDCtjt9/tmj8djkPl8PlO32zVLWQ4AVucVzim7chU8xblms8n8fj8nc7lcLBgMsnq9Lrb8WLodKuEuk8qqMRgMro7H4xfE+wHsV16vdx8JnU4nCwQCTlD9BuY+UU3rdIdV4uFncovKBtP0PJPJXBZKFUX5SWosIvNwwT+SMvFgqOR+uFgsNoecSCRMECx43gHxDuAa9O2pVIrlcjkTlN/OZDJB/9dSESDZDGOhirahPX1twLguvSLfAV3AS0mhlTL8Vrw04tzhwbhh3AowOEajETpbAHek5LynjCOZlSojKs0fYgycOwrC42D81UBUq1W+gsPh4C1sATP9mVa2CoWYkE6ncyzuKLa1Wo1zoLA/vKDj//bZmWcAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "GitHub",
                "title": "GitHub",
                "src": "/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png",
                "srcSet": ["/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png 21w"],
                "sizes": "(max-width: 21px) 100vw, 21px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></a>
        </ResourceCard>
      </Column>
      <Column colMd={6} colLg={9} noGutterMdLeft mdxType="Column">
NiFi flows that consume data from the Health Record Ingestion service
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={2} colLg={3} noGutterMdLeft mdxType="Column">
        <ResourceCard actionIcon="" color="dark" title="Data De-Identification" mdxType="ResourceCard">
          <a {...{
            "href": "https://github.com/Alvearie/de-identification"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "21px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACxklEQVQ4y41UzYtSURS/Mo6OJTS7SZjAv6I2QVjjMAu3/QHiIqQoq81ItBJcJO1bFdimhcm0EKSgNklKFooIiiSKH/iFqOHX8z1f51zPfXNnaKgHv3fvufec3/3de+65TNd1JhCLxdje3p4ln8/fA1tfr9cLffOtCcZYoVC4b7VaLdFo1IiHOcZkwmQyySKRiB2cH1DwCrDWTz80V9gpFouBUChkj8fjZzjwZxLsgAMpUAFoRKgi1psPxxSwNXI8wliZUMh1iy2dFcXH9AvGFtR3G4Rg3ITObXJYAqZgp6FNr1arrqqqc7BTCOjPYKwHc9/IB32XtJhbKBSrT6n/VdM0vlq5XD7o9XpPZrMZm8/nDPqPKpXKEc6hD/h+IcFT4rAwkq2Kw4avTvJ30+k0a7fbbLlcXgLYWq0Wy2azfI58fonkYRMOh/eZyCI1mIhneMigYKvRaGwNh0MzgBHMOIZzlMRjact6qVQ6YZRRjdTlaWUbP48LPkqijUh/kCDOIRRqtEqZCM3/IgRsU1ukWBCuKecVGoT81v8nIcVuFFJJiUsqtnzFuFd/367sk5UI54xuv0jMb8BDCtjt9/tmj8djkPl8PlO32zVLWQ4AVucVzim7chU8xblms8n8fj8nc7lcLBgMsnq9Lrb8WLodKuEuk8qqMRgMro7H4xfE+wHsV16vdx8JnU4nCwQCTlD9BuY+UU3rdIdV4uFncovKBtP0PJPJXBZKFUX5SWosIvNwwT+SMvFgqOR+uFgsNoecSCRMECx43gHxDuAa9O2pVIrlcjkTlN/OZDJB/9dSESDZDGOhirahPX1twLguvSLfAV3AS0mhlTL8Vrw04tzhwbhh3AowOEajETpbAHek5LynjCOZlSojKs0fYgycOwrC42D81UBUq1W+gsPh4C1sATP9mVa2CoWYkE6ncyzuKLa1Wo1zoLA/vKDj//bZmWcAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "GitHub",
                "title": "GitHub",
                "src": "/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png",
                "srcSet": ["/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png 21w"],
                "sizes": "(max-width: 21px) 100vw, 21px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></a>
        </ResourceCard>
      </Column>
      <Column colMd={6} colLg={9} noGutterMdLeft mdxType="Column">
A wide range of de-identification capabilities designed to support GDPR, HIPAA, CCPA and other privacy frameworks allowing organizations to meet their regulatory and privacy requirements.
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={2} colLg={3} noGutterMdLeft mdxType="Column">
        <ResourceCard actionIcon="launch" color="dark" title="Cohort & Quality Measure Service" href="https://alvearie.github.io/quality-measure-and-cohort-service/" mdxType="ResourceCard">
          <a {...{
            "href": "https://github.com/Alvearie/quality-measure-and-cohort-service"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "21px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACxklEQVQ4y41UzYtSURS/Mo6OJTS7SZjAv6I2QVjjMAu3/QHiIqQoq81ItBJcJO1bFdimhcm0EKSgNklKFooIiiSKH/iFqOHX8z1f51zPfXNnaKgHv3fvufec3/3de+65TNd1JhCLxdje3p4ln8/fA1tfr9cLffOtCcZYoVC4b7VaLdFo1IiHOcZkwmQyySKRiB2cH1DwCrDWTz80V9gpFouBUChkj8fjZzjwZxLsgAMpUAFoRKgi1psPxxSwNXI8wliZUMh1iy2dFcXH9AvGFtR3G4Rg3ITObXJYAqZgp6FNr1arrqqqc7BTCOjPYKwHc9/IB32XtJhbKBSrT6n/VdM0vlq5XD7o9XpPZrMZm8/nDPqPKpXKEc6hD/h+IcFT4rAwkq2Kw4avTvJ30+k0a7fbbLlcXgLYWq0Wy2azfI58fonkYRMOh/eZyCI1mIhneMigYKvRaGwNh0MzgBHMOIZzlMRjact6qVQ6YZRRjdTlaWUbP48LPkqijUh/kCDOIRRqtEqZCM3/IgRsU1ukWBCuKecVGoT81v8nIcVuFFJJiUsqtnzFuFd/367sk5UI54xuv0jMb8BDCtjt9/tmj8djkPl8PlO32zVLWQ4AVucVzim7chU8xblms8n8fj8nc7lcLBgMsnq9Lrb8WLodKuEuk8qqMRgMro7H4xfE+wHsV16vdx8JnU4nCwQCTlD9BuY+UU3rdIdV4uFncovKBtP0PJPJXBZKFUX5SWosIvNwwT+SMvFgqOR+uFgsNoecSCRMECx43gHxDuAa9O2pVIrlcjkTlN/OZDJB/9dSESDZDGOhirahPX1twLguvSLfAV3AS0mhlTL8Vrw04tzhwbhh3AowOEajETpbAHek5LynjCOZlSojKs0fYgycOwrC42D81UBUq1W+gsPh4C1sATP9mVa2CoWYkE6ncyzuKLa1Wo1zoLA/vKDj//bZmWcAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "GitHub",
                "title": "GitHub",
                "src": "/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png",
                "srcSet": ["/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png 21w"],
                "sizes": "(max-width: 21px) 100vw, 21px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></a>
        </ResourceCard>
      </Column>
      <Column colMd={6} colLg={9} noGutterMdLeft mdxType="Column">
Service to evaluate quality measure and cohort specifications against a target patient data set.
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={2} colLg={3} noGutterMdLeft mdxType="Column">
        <ResourceCard actionIcon="launch" color="dark" title="Alvearie FHIR Implementation Guide" href="https://alvearie.io/alvearie-fhir-ig/0.1.0/" mdxType="ResourceCard">
          <a {...{
            "href": "https://github.com/Alvearie/alvearie-fhir-ig"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "21px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACxklEQVQ4y41UzYtSURS/Mo6OJTS7SZjAv6I2QVjjMAu3/QHiIqQoq81ItBJcJO1bFdimhcm0EKSgNklKFooIiiSKH/iFqOHX8z1f51zPfXNnaKgHv3fvufec3/3de+65TNd1JhCLxdje3p4ln8/fA1tfr9cLffOtCcZYoVC4b7VaLdFo1IiHOcZkwmQyySKRiB2cH1DwCrDWTz80V9gpFouBUChkj8fjZzjwZxLsgAMpUAFoRKgi1psPxxSwNXI8wliZUMh1iy2dFcXH9AvGFtR3G4Rg3ITObXJYAqZgp6FNr1arrqqqc7BTCOjPYKwHc9/IB32XtJhbKBSrT6n/VdM0vlq5XD7o9XpPZrMZm8/nDPqPKpXKEc6hD/h+IcFT4rAwkq2Kw4avTvJ30+k0a7fbbLlcXgLYWq0Wy2azfI58fonkYRMOh/eZyCI1mIhneMigYKvRaGwNh0MzgBHMOIZzlMRjact6qVQ6YZRRjdTlaWUbP48LPkqijUh/kCDOIRRqtEqZCM3/IgRsU1ukWBCuKecVGoT81v8nIcVuFFJJiUsqtnzFuFd/367sk5UI54xuv0jMb8BDCtjt9/tmj8djkPl8PlO32zVLWQ4AVucVzim7chU8xblms8n8fj8nc7lcLBgMsnq9Lrb8WLodKuEuk8qqMRgMro7H4xfE+wHsV16vdx8JnU4nCwQCTlD9BuY+UU3rdIdV4uFncovKBtP0PJPJXBZKFUX5SWosIvNwwT+SMvFgqOR+uFgsNoecSCRMECx43gHxDuAa9O2pVIrlcjkTlN/OZDJB/9dSESDZDGOhirahPX1twLguvSLfAV3AS0mhlTL8Vrw04tzhwbhh3AowOEajETpbAHek5LynjCOZlSojKs0fYgycOwrC42D81UBUq1W+gsPh4C1sATP9mVa2CoWYkE6ncyzuKLa1Wo1zoLA/vKDj//bZmWcAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "GitHub",
                "title": "GitHub",
                "src": "/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png",
                "srcSet": ["/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png 21w"],
                "sizes": "(max-width: 21px) 100vw, 21px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></a>
        </ResourceCard>
      </Column>
      <Column colMd={6} colLg={9} noGutterMdLeft mdxType="Column">
A FHIR Implementation Guide that extends the FHIR v4 Base. 
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={2} colLg={3} noGutterMdLeft mdxType="Column">
        <ResourceCard actionIcon="" color="dark" title="DICOM to FHIR Converter" mdxType="ResourceCard">
          <a {...{
            "href": "https://github.com/LinuxForHealth/dicom-fhir-converter"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "21px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACxklEQVQ4y41UzYtSURS/Mo6OJTS7SZjAv6I2QVjjMAu3/QHiIqQoq81ItBJcJO1bFdimhcm0EKSgNklKFooIiiSKH/iFqOHX8z1f51zPfXNnaKgHv3fvufec3/3de+65TNd1JhCLxdje3p4ln8/fA1tfr9cLffOtCcZYoVC4b7VaLdFo1IiHOcZkwmQyySKRiB2cH1DwCrDWTz80V9gpFouBUChkj8fjZzjwZxLsgAMpUAFoRKgi1psPxxSwNXI8wliZUMh1iy2dFcXH9AvGFtR3G4Rg3ITObXJYAqZgp6FNr1arrqqqc7BTCOjPYKwHc9/IB32XtJhbKBSrT6n/VdM0vlq5XD7o9XpPZrMZm8/nDPqPKpXKEc6hD/h+IcFT4rAwkq2Kw4avTvJ30+k0a7fbbLlcXgLYWq0Wy2azfI58fonkYRMOh/eZyCI1mIhneMigYKvRaGwNh0MzgBHMOIZzlMRjact6qVQ6YZRRjdTlaWUbP48LPkqijUh/kCDOIRRqtEqZCM3/IgRsU1ukWBCuKecVGoT81v8nIcVuFFJJiUsqtnzFuFd/367sk5UI54xuv0jMb8BDCtjt9/tmj8djkPl8PlO32zVLWQ4AVucVzim7chU8xblms8n8fj8nc7lcLBgMsnq9Lrb8WLodKuEuk8qqMRgMro7H4xfE+wHsV16vdx8JnU4nCwQCTlD9BuY+UU3rdIdV4uFncovKBtP0PJPJXBZKFUX5SWosIvNwwT+SMvFgqOR+uFgsNoecSCRMECx43gHxDuAa9O2pVIrlcjkTlN/OZDJB/9dSESDZDGOhirahPX1twLguvSLfAV3AS0mhlTL8Vrw04tzhwbhh3AowOEajETpbAHek5LynjCOZlSojKs0fYgycOwrC42D81UBUq1W+gsPh4C1sATP9mVa2CoWYkE6ncyzuKLa1Wo1zoLA/vKDj//bZmWcAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "GitHub",
                "title": "GitHub",
                "src": "/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png",
                "srcSet": ["/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png 21w"],
                "sizes": "(max-width: 21px) 100vw, 21px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></a>
        </ResourceCard>
      </Column>
      <Column colMd={6} colLg={9} noGutterMdLeft mdxType="Column">
A service that transforms DICOM resoures to FHIR resources.
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={2} colLg={3} noGutterMdLeft mdxType="Column">
        <ResourceCard actionIcon="" color="dark" title="HL7 to FHIR Converter" mdxType="ResourceCard">
          <a {...{
            "href": "https://github.com/LinuxForHealth/hl7v2-fhir-converter"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "21px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACxklEQVQ4y41UzYtSURS/Mo6OJTS7SZjAv6I2QVjjMAu3/QHiIqQoq81ItBJcJO1bFdimhcm0EKSgNklKFooIiiSKH/iFqOHX8z1f51zPfXNnaKgHv3fvufec3/3de+65TNd1JhCLxdje3p4ln8/fA1tfr9cLffOtCcZYoVC4b7VaLdFo1IiHOcZkwmQyySKRiB2cH1DwCrDWTz80V9gpFouBUChkj8fjZzjwZxLsgAMpUAFoRKgi1psPxxSwNXI8wliZUMh1iy2dFcXH9AvGFtR3G4Rg3ITObXJYAqZgp6FNr1arrqqqc7BTCOjPYKwHc9/IB32XtJhbKBSrT6n/VdM0vlq5XD7o9XpPZrMZm8/nDPqPKpXKEc6hD/h+IcFT4rAwkq2Kw4avTvJ30+k0a7fbbLlcXgLYWq0Wy2azfI58fonkYRMOh/eZyCI1mIhneMigYKvRaGwNh0MzgBHMOIZzlMRjact6qVQ6YZRRjdTlaWUbP48LPkqijUh/kCDOIRRqtEqZCM3/IgRsU1ukWBCuKecVGoT81v8nIcVuFFJJiUsqtnzFuFd/367sk5UI54xuv0jMb8BDCtjt9/tmj8djkPl8PlO32zVLWQ4AVucVzim7chU8xblms8n8fj8nc7lcLBgMsnq9Lrb8WLodKuEuk8qqMRgMro7H4xfE+wHsV16vdx8JnU4nCwQCTlD9BuY+UU3rdIdV4uFncovKBtP0PJPJXBZKFUX5SWosIvNwwT+SMvFgqOR+uFgsNoecSCRMECx43gHxDuAa9O2pVIrlcjkTlN/OZDJB/9dSESDZDGOhirahPX1twLguvSLfAV3AS0mhlTL8Vrw04tzhwbhh3AowOEajETpbAHek5LynjCOZlSojKs0fYgycOwrC42D81UBUq1W+gsPh4C1sATP9mVa2CoWYkE6ncyzuKLa1Wo1zoLA/vKDj//bZmWcAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "GitHub",
                "title": "GitHub",
                "src": "/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png",
                "srcSet": ["/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png 21w"],
                "sizes": "(max-width: 21px) 100vw, 21px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></a>
        </ResourceCard>
      </Column>
      <Column colMd={6} colLg={9} noGutterMdLeft mdxType="Column">
A java-based library that enables conversion of HL7 v2 messages to FHIR resources
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={2} colLg={3} noGutterMdLeft mdxType="Column">
        <ResourceCard actionIcon="launch" color="dark" title="Imaging Ingestion" href="https://github.com/Alvearie/imaging-ingestion/" mdxType="ResourceCard">
          <a {...{
            "href": "https://github.com/Alvearie/imaging-ingestion"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "21px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACxklEQVQ4y41UzYtSURS/Mo6OJTS7SZjAv6I2QVjjMAu3/QHiIqQoq81ItBJcJO1bFdimhcm0EKSgNklKFooIiiSKH/iFqOHX8z1f51zPfXNnaKgHv3fvufec3/3de+65TNd1JhCLxdje3p4ln8/fA1tfr9cLffOtCcZYoVC4b7VaLdFo1IiHOcZkwmQyySKRiB2cH1DwCrDWTz80V9gpFouBUChkj8fjZzjwZxLsgAMpUAFoRKgi1psPxxSwNXI8wliZUMh1iy2dFcXH9AvGFtR3G4Rg3ITObXJYAqZgp6FNr1arrqqqc7BTCOjPYKwHc9/IB32XtJhbKBSrT6n/VdM0vlq5XD7o9XpPZrMZm8/nDPqPKpXKEc6hD/h+IcFT4rAwkq2Kw4avTvJ30+k0a7fbbLlcXgLYWq0Wy2azfI58fonkYRMOh/eZyCI1mIhneMigYKvRaGwNh0MzgBHMOIZzlMRjact6qVQ6YZRRjdTlaWUbP48LPkqijUh/kCDOIRRqtEqZCM3/IgRsU1ukWBCuKecVGoT81v8nIcVuFFJJiUsqtnzFuFd/367sk5UI54xuv0jMb8BDCtjt9/tmj8djkPl8PlO32zVLWQ4AVucVzim7chU8xblms8n8fj8nc7lcLBgMsnq9Lrb8WLodKuEuk8qqMRgMro7H4xfE+wHsV16vdx8JnU4nCwQCTlD9BuY+UU3rdIdV4uFncovKBtP0PJPJXBZKFUX5SWosIvNwwT+SMvFgqOR+uFgsNoecSCRMECx43gHxDuAa9O2pVIrlcjkTlN/OZDJB/9dSESDZDGOhirahPX1twLguvSLfAV3AS0mhlTL8Vrw04tzhwbhh3AowOEajETpbAHek5LynjCOZlSojKs0fYgycOwrC42D81UBUq1W+gsPh4C1sATP9mVa2CoWYkE6ncyzuKLa1Wo1zoLA/vKDj//bZmWcAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "GitHub",
                "title": "GitHub",
                "src": "/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png",
                "srcSet": ["/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png 21w"],
                "sizes": "(max-width: 21px) 100vw, 21px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></a>
        </ResourceCard>
      </Column>
      <Column colMd={6} colLg={9} noGutterMdLeft mdxType="Column">
The Imaging Ingestion component brings a number of capabilities to the Alvearie ecosystem. It provides all of the necessary capability to extend the reach of existing enterprise medical imaging systems to a modern hybrid cloud.
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={2} colLg={3} noGutterMdLeft mdxType="Column">
        <ResourceCard actionIcon="" color="dark" title="Health Data Traceability and Logging" mdxType="ResourceCard">
          <a {...{
            "href": "https://github.com/Alvearie/health-data-traceability-logging"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "21px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACxklEQVQ4y41UzYtSURS/Mo6OJTS7SZjAv6I2QVjjMAu3/QHiIqQoq81ItBJcJO1bFdimhcm0EKSgNklKFooIiiSKH/iFqOHX8z1f51zPfXNnaKgHv3fvufec3/3de+65TNd1JhCLxdje3p4ln8/fA1tfr9cLffOtCcZYoVC4b7VaLdFo1IiHOcZkwmQyySKRiB2cH1DwCrDWTz80V9gpFouBUChkj8fjZzjwZxLsgAMpUAFoRKgi1psPxxSwNXI8wliZUMh1iy2dFcXH9AvGFtR3G4Rg3ITObXJYAqZgp6FNr1arrqqqc7BTCOjPYKwHc9/IB32XtJhbKBSrT6n/VdM0vlq5XD7o9XpPZrMZm8/nDPqPKpXKEc6hD/h+IcFT4rAwkq2Kw4avTvJ30+k0a7fbbLlcXgLYWq0Wy2azfI58fonkYRMOh/eZyCI1mIhneMigYKvRaGwNh0MzgBHMOIZzlMRjact6qVQ6YZRRjdTlaWUbP48LPkqijUh/kCDOIRRqtEqZCM3/IgRsU1ukWBCuKecVGoT81v8nIcVuFFJJiUsqtnzFuFd/367sk5UI54xuv0jMb8BDCtjt9/tmj8djkPl8PlO32zVLWQ4AVucVzim7chU8xblms8n8fj8nc7lcLBgMsnq9Lrb8WLodKuEuk8qqMRgMro7H4xfE+wHsV16vdx8JnU4nCwQCTlD9BuY+UU3rdIdV4uFncovKBtP0PJPJXBZKFUX5SWosIvNwwT+SMvFgqOR+uFgsNoecSCRMECx43gHxDuAa9O2pVIrlcjkTlN/OZDJB/9dSESDZDGOhirahPX1twLguvSLfAV3AS0mhlTL8Vrw04tzhwbhh3AowOEajETpbAHek5LynjCOZlSojKs0fYgycOwrC42D81UBUq1W+gsPh4C1sATP9mVa2CoWYkE6ncyzuKLa1Wo1zoLA/vKDj//bZmWcAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "GitHub",
                "title": "GitHub",
                "src": "/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png",
                "srcSet": ["/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png 21w"],
                "sizes": "(max-width: 21px) 100vw, 21px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></a>
        </ResourceCard>
      </Column>
      <Column colMd={6} colLg={9} noGutterMdLeft mdxType="Column">
A framework for healthcare applications to address regulatory requirements related to collecting, retaining, protecting, and monitoring logs of access to Protected Health Information (PHI).
      </Column>
    </Row>
    <hr />
    <p />
    <h2>{`Related Projects`}</h2>
    <Row mdxType="Row">
      <Column colMd={2} colLg={3} noGutterMdLeft mdxType="Column">
        <ResourceCard actionIcon="launch" color="dark" title="Linux for Health Connect" href="https://linuxforhealth.github.io/docs/" mdxType="ResourceCard">
          <a {...{
            "href": "https://github.com/LinuxForHealth/connect"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "21px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACxklEQVQ4y41UzYtSURS/Mo6OJTS7SZjAv6I2QVjjMAu3/QHiIqQoq81ItBJcJO1bFdimhcm0EKSgNklKFooIiiSKH/iFqOHX8z1f51zPfXNnaKgHv3fvufec3/3de+65TNd1JhCLxdje3p4ln8/fA1tfr9cLffOtCcZYoVC4b7VaLdFo1IiHOcZkwmQyySKRiB2cH1DwCrDWTz80V9gpFouBUChkj8fjZzjwZxLsgAMpUAFoRKgi1psPxxSwNXI8wliZUMh1iy2dFcXH9AvGFtR3G4Rg3ITObXJYAqZgp6FNr1arrqqqc7BTCOjPYKwHc9/IB32XtJhbKBSrT6n/VdM0vlq5XD7o9XpPZrMZm8/nDPqPKpXKEc6hD/h+IcFT4rAwkq2Kw4avTvJ30+k0a7fbbLlcXgLYWq0Wy2azfI58fonkYRMOh/eZyCI1mIhneMigYKvRaGwNh0MzgBHMOIZzlMRjact6qVQ6YZRRjdTlaWUbP48LPkqijUh/kCDOIRRqtEqZCM3/IgRsU1ukWBCuKecVGoT81v8nIcVuFFJJiUsqtnzFuFd/367sk5UI54xuv0jMb8BDCtjt9/tmj8djkPl8PlO32zVLWQ4AVucVzim7chU8xblms8n8fj8nc7lcLBgMsnq9Lrb8WLodKuEuk8qqMRgMro7H4xfE+wHsV16vdx8JnU4nCwQCTlD9BuY+UU3rdIdV4uFncovKBtP0PJPJXBZKFUX5SWosIvNwwT+SMvFgqOR+uFgsNoecSCRMECx43gHxDuAa9O2pVIrlcjkTlN/OZDJB/9dSESDZDGOhirahPX1twLguvSLfAV3AS0mhlTL8Vrw04tzhwbhh3AowOEajETpbAHek5LynjCOZlSojKs0fYgycOwrC42D81UBUq1W+gsPh4C1sATP9mVa2CoWYkE6ncyzuKLa1Wo1zoLA/vKDj//bZmWcAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "GitHub",
                "title": "GitHub",
                "src": "/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png",
                "srcSet": ["/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png 21w"],
                "sizes": "(max-width: 21px) 100vw, 21px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></a>
        </ResourceCard>
      </Column>
      <Column colMd={6} colLg={9} noGutterMdLeft mdxType="Column">
LinuxForHealth (LFH) is a distributed processing network operating system which allows edge devices (IoT, workstations, app servers) to connect directly to health care transaction systems. The processing model abstracts the need for intermediary third-party organizations, resulting in a developer-extensible trust protocol.
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={2} colLg={3} noGutterMdLeft mdxType="Column">
        <ResourceCard actionIcon="launch" color="dark" title="Intelligent Data as a Service (iDaaS)" href="https://connectedhealth-idaas.io/" mdxType="ResourceCard">
          <a {...{
            "href": "https://github.com/RedHat-Healthcare"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "21px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACxklEQVQ4y41UzYtSURS/Mo6OJTS7SZjAv6I2QVjjMAu3/QHiIqQoq81ItBJcJO1bFdimhcm0EKSgNklKFooIiiSKH/iFqOHX8z1f51zPfXNnaKgHv3fvufec3/3de+65TNd1JhCLxdje3p4ln8/fA1tfr9cLffOtCcZYoVC4b7VaLdFo1IiHOcZkwmQyySKRiB2cH1DwCrDWTz80V9gpFouBUChkj8fjZzjwZxLsgAMpUAFoRKgi1psPxxSwNXI8wliZUMh1iy2dFcXH9AvGFtR3G4Rg3ITObXJYAqZgp6FNr1arrqqqc7BTCOjPYKwHc9/IB32XtJhbKBSrT6n/VdM0vlq5XD7o9XpPZrMZm8/nDPqPKpXKEc6hD/h+IcFT4rAwkq2Kw4avTvJ30+k0a7fbbLlcXgLYWq0Wy2azfI58fonkYRMOh/eZyCI1mIhneMigYKvRaGwNh0MzgBHMOIZzlMRjact6qVQ6YZRRjdTlaWUbP48LPkqijUh/kCDOIRRqtEqZCM3/IgRsU1ukWBCuKecVGoT81v8nIcVuFFJJiUsqtnzFuFd/367sk5UI54xuv0jMb8BDCtjt9/tmj8djkPl8PlO32zVLWQ4AVucVzim7chU8xblms8n8fj8nc7lcLBgMsnq9Lrb8WLodKuEuk8qqMRgMro7H4xfE+wHsV16vdx8JnU4nCwQCTlD9BuY+UU3rdIdV4uFncovKBtP0PJPJXBZKFUX5SWosIvNwwT+SMvFgqOR+uFgsNoecSCRMECx43gHxDuAa9O2pVIrlcjkTlN/OZDJB/9dSESDZDGOhirahPX1twLguvSLfAV3AS0mhlTL8Vrw04tzhwbhh3AowOEajETpbAHek5LynjCOZlSojKs0fYgycOwrC42D81UBUq1W+gsPh4C1sATP9mVa2CoWYkE6ncyzuKLa1Wo1zoLA/vKDj//bZmWcAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "GitHub",
                "title": "GitHub",
                "src": "/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png",
                "srcSet": ["/static/e1a3fb4f5c0ece0dc602f2a76aaa29b7/9f63b/gitHubWhiteSmall.png 21w"],
                "sizes": "(max-width: 21px) 100vw, 21px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></a>
        </ResourceCard>
      </Column>
      <Column colMd={6} colLg={9} noGutterMdLeft mdxType="Column">
iDaaS is a health industry design pattern and reusable framework that is a blueprint for
accessing, connecting, and integrating to/with health data at scale, batch and real-time.
It is intended to be deployed on Red Hat's open source integration products (<a href="https://www.3scale.net/">3Scale</a>, <a href="https://www.redhat.com/en/technologies/jboss-middleware/fuse">Fuse</a>, <a href="https://www.redhat.com/en/technologies/jboss-middleware/amq">AMQ Streams</a>)
which are supported versions of open source community projects.
      </Column>
    </Row>
    <p />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      